import consumer from "./consumer"

const message = document.querySelector("#message");

if (message != null) {
  const messageId = message.dataset.messageId;
  consumer.subscriptions.create({ channel: "MessageRepliesChannel", message: messageId}, {
    received(data) {
      const element = document.querySelector("#message-replies");
      element.insertAdjacentHTML("beforeend", data.message)
    }
  });
};
